.sidedrawer {
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 250px;
    z-index: 300;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    overflow-y: auto;
}

.sidedrawer.open {
    transform: translateX(0);
}

.sidedrawer-item {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
}

.sidedrawer .link-item {
    display: block;
    margin-right: 20px;
    padding: 10px;
    font-size: 15px;
}

.sidedrawer-item.active, .sidedrawer .link-item.active {
    font-weight: bold;
    border-right: 4px solid rgb(0, 0, 0, 0.25);
}

.seperating-line {
    height: 1px;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}

.sidedrawer .logo {
    margin: 10px;
    width: 120px;
}

.side-icon {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-left: 5px;
}