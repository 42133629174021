.show-tree {
    max-height: 336px; /* 42 x number of child elements (categories) */
    height: 336px;
    transition: 0.5s ease-out;
}

.hide-tree {
    max-height: 0px;
    transition: 0.5s ease-in;
}

.rotate-down {
    transform: rotate(-90deg);
    transition: 0.1s;
}

.rotate-up {
    transform: rotate(90deg);
    transition: 0.1s;
}