.footer {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-top: 2px solid rgb(0, 0, 0, 0.1);
    margin: 0px 2vw 5px;
    font-size: 0.9rem;
}

.icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
}

.icon {
    height: 25px;
    width: auto;
    padding: 2px 5px 0px;
}

.credit {
    text-align: left;
    color: grey;
}

@media screen and (max-width: 767px) {
    .footer {
        font-size: 0.7rem;
    }
    .icon {
        height: 20px;
        padding: 2px 3px 0px;
    }
}