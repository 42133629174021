.page-container {
  /*The calculation of min height is used to place footer at the bottom. 
  it goes like this: 60px for the navbar, 20 for the page's padding and 32 for the footer*/
  min-height: calc(100vh - 112px);
  padding: 10px;
}

.button {
  margin: 5px 0px 5px 5px;
  padding: 5px;
  color: rgba(0, 0, 0, .8);
  background: #e0e1e2 none;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
}

.button:hover {
  background: #c9c9c9 none;
}

.button.red {
  background-color: red;
  color: white
}

.button.green {
  background-color: green;
  color: white
}

.button.blue {
  background-color: blue;
  color: white
}


/*=============== quill ============*/

.ql-bubble .ql-editor {
  padding: 0px;
  overflow: hidden;
}

.ql-hidden {
  display: none;
}

.form .ql-editor, .abroad-edit .ql-editor {
  min-height: 300px;
}

.ql-editor {
  font-size: medium;
}

.quill {
  max-width: 1000px;
}

/*fix rtl side effects*/

.ql-snow .ql-picker-label {
  padding-right: 28px;
}
