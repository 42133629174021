body, html {
  margin: 0;
  font-family: 'Heebo-VariableFont_wght', Arial, Helvetica, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  padding-top: 60px;
}

@font-face {
  font-family: 'Heebo-VariableFont_wght';
  src: local('Heebo-VariableFont_wght'), url(./fonts/Heebo-VariableFont_wght.ttf) format('truetype');
}

* {
  font-family: 'Heebo-VariableFont_wght', Arial, Helvetica, sans-serif;
  padding: 0px;
  margin: 0px;
}

*, *:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

li {
  list-style: none;
}