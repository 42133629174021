#navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0px 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    box-shadow: 0px 2px 5px -4px black;
    z-index: 100;
    font-weight: bold;
    color: white;
}

/* The container of all of the links */

#navbar .nav-links {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
}

.nav-active, #navbar .nav-links>li>a:hover {
    box-shadow: 0px -3px 0px yellow inset;
    color: yellow;
}

/* The links */

#navbar .nav-links>li {
    margin: 0px 10px;
    position: relative;
}

#navbar .nav-links>li>a {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 60px;
}

.logo {
    display: block;
    height: auto;
    width: 140px;
    padding: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

/* Hamburger */

.hamburger {
    height: 30%;
    margin-left: auto;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 10px;
    cursor: pointer;
}

.line {
    background-color: rgb(255, 255, 255);
    height: 2px;
    width: 20px;
}


@media only screen and (max-width: 1000px) {
    #navbar {
        display: flex;
    }
    #navbar .nav-links {
        display: none;
    }
    .logo {
        width: 100px;
    }
}

@media only screen and (min-width: 1001px) {
    .hamburger {
        display: none;
    }
}